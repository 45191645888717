module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-26201270-8","head":true,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":690,"backgroundColor":"#f7f0eb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/about","/loremipsum","/typed-poems"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GatsbyJS","short_name":"GatsbyJS","start_url":"/","icon":"./src/images/emoji-stars.png","include_favicon":false,"background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","localize":[{"start_url":"/en/","lang":"en","name":"Tomer Lichtash","short_name":"@tomerlichtash","description":"Homepage/Page - Tomer Lichtash"},{"start_url":"/he/","lang":"en","name":"תומר ליכטש","short_name":"@tomerlichtash","description":"דף/בית - תומר ליכטש"}]},
    }]
