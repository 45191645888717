// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-loremipsum-tsx": () => import("../src/pages/loremipsum.tsx" /* webpackChunkName: "component---src-pages-loremipsum-tsx" */),
  "component---src-pages-notes-tsx": () => import("../src/pages/notes.tsx" /* webpackChunkName: "component---src-pages-notes-tsx" */),
  "component---src-pages-typed-poems-tsx": () => import("../src/pages/typed-poems.tsx" /* webpackChunkName: "component---src-pages-typed-poems-tsx" */)
}

